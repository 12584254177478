<template>
    <vx-card :title="'Pick The Actual Items (' + this.status + ')'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Picking Code</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="pickCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>

        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table class="table table-bordered" style="width:100%">
                    <thead class="vs-table--thead">
                        <tr>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">SKU Code</th>
                            <th rowspan="2" style="vertical-align: middle; text-align:center;">SKU Description</th>
                            <th colspan="5" style="vertical-align: middle; text-align:center;">Actual</th>
                        </tr>
                        <tr>
                            <th style="vertical-align: middle; text-align:center;">HU</th>
                            <th style="vertical-align: middle; text-align:center;">Qty</th>
                            <th style="vertical-align: middle; text-align:center;">Storage Area</th>
                            <th style="vertical-align: middle; text-align:center;">Batch Number #</th>
                            <th style="vertical-align: middle; text-align:center;">Expired Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="(skuLine, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLine.sku_code }}
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="skuLines[index].actual_lines.length">
                                    {{ skuLine.item_name }}
                                </td>

                                <!-- first actual lines -->
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].selected_hu }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].qty }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].storage_area }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ skuLines[index].actual_lines[0].batch_number }}
                                </td>
                                <td class="td vs-table--td" style="padding: 5px">
                                    {{ formatDate(skuLines[index].actual_lines[0].expired_date) }}
                                </td>
                            </tr>

                            <!-- added actual lines -->
                            <template v-for="(aLine, idxALine) in skuLines[index].actual_lines">
                                <tr style="padding-bottom: 5px" :key="index + '-' + idxALine" v-if="idxALine > 0">
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ skuLines[index].actual_lines[idxALine].selected_hu }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ skuLines[index].actual_lines[idxALine].qty }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ skuLines[index].actual_lines[idxALine].storage_area }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ skuLines[index].actual_lines[idxALine].batch_number }}
                                    </td>
                                    <td class="td vs-table--td" style="padding: 5px">
                                        {{ formatDate(skuLines[index].actual_lines[idxALine].expired_date) }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    right: 0%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            action: null,
            status: null,
            code: null,
            pickID: null,
            pickCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            loadingDate: null,
            skuLines: [
                {
                    selected_sku: null,
                    actual_lines: [{
                        item_stock_id: 0,
                        selected_hu: null,
                        qty: 0,
                        storage_area: null,
                        batch_number: null,
                        expired_date: null,
                    }]
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            console.log(this.id)
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/v1/canvas/detail-confirmed-on-process/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.status = resp.data.status
                        this.code = resp.data.code
                        this.selectedWarehouse = resp.data.selected_warehouse.label
                        this.selectedTerritory = resp.data.selected_territory.label
                        this.selectedSales = resp.data.selected_sales.label
                        this.loadingDate = moment(resp.data.loading_date).toDate()
                        this.pickID = resp.data.pick_id
                        this.pickCode = resp.data.pick_code
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        console.log("failed to get data", resp)
                        this.$vs.loading.close();
                    }
                })
        },
        handleBack() {
            this.$router.push({
                name: "canvas.item-request",
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>